import { useState } from "react";
import { useWorkoutsContext } from "../hooks/useWorkoutsContext";
import { useAuthContext } from "../hooks/useAuthContext";

const WorkoutForm = () => {
  const { dispatch } = useWorkoutsContext();
  const { user } = useAuthContext();

  const [title, setTitle] = useState("");
  const [load, setLoad] = useState("");
  const [reps, setReps] = useState("");
  const [error, setError] = useState(null);
  const [emptyFields, setEmptyFields] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!user) {
      setError("You must be logged in");
      return;
    }

    const workout = { title, load, reps };

    const response = await fetch(
      "https://track-your-lifts-backend.onrender.com/api/workouts",
      {
        method: "POST",
        body: JSON.stringify(workout),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    const json = await response.json();

    if (!response.ok) {
      setError(json.error);
      setEmptyFields(json.emptyFields);
    }
    if (response.ok) {
      setTitle("");
      setLoad("");
      setReps("");
      setError(null);
      setEmptyFields([]);

      dispatch({ type: "CREATE_WORKOUT", payload: json });
    }
  };

  return (
    <div className="py-5 border-b-4 border-indigo-500">
      <h2>Add a workout</h2>

      <form
        className="space-y-6"
        action="#"
        method="POST"
        onSubmit={handleSubmit}
      >
        <div>
          <label
            htmlFor="title"
            className="block text-sm font-medium leading-6 text-secondary"
          >
            Title
          </label>
          <div className="mt-2">
            <input
              id="title"
              name="title"
              type="text"
              onChange={(e) => setTitle(e.target.value)}
              value={title}
              required
              className="block w-full rounded-md border-2 border-indigo-500 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        <div>
          <label
            htmlFor="load"
            className="block text-sm font-medium leading-6 text-secondary"
          >
            Load
          </label>

          <div className="mt-2">
            <input
              id="load"
              name="load"
              type="text"
              onChange={(e) => setLoad(e.target.value)}
              value={load}
              required
              className="block w-full rounded-md border-2 border-indigo-500 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="reps"
            className="block text-sm font-medium leading-6 text-secondary"
          >
            Reps
          </label>

          <div className="mt-2">
            <input
              id="reps"
              name="Reps"
              type="text"
              onChange={(e) => setReps(e.target.value)}
              value={reps}
              required
              className="block w-full rounded-md border-2 border-indigo-500 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        <div>
          <button
            type="submit"
            className="flex w-full justify-center rounded-md bg-indigo-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default WorkoutForm;

{
  /* <form className="create" onSubmit={handleSubmit}>
<h3>Add a New Workout</h3>

<label>Excercise Title:</label>
<input
  type="text"
  onChange={(e) => setTitle(e.target.value)}
  value={title}
  className={emptyFields.includes("title") ? "error" : ""}
/>

<label>Load (kg):</label>
<input
  type="number"
  onChange={(e) => setLoad(e.target.value)}
  value={load}
  className={emptyFields.includes("load") ? "error" : ""}
/>

<label>Reps:</label>
<input
  type="number"
  onChange={(e) => setReps(e.target.value)}
  value={reps}
  className={emptyFields.includes("reps") ? "error" : ""}
/>

<button>Add Workout</button>
{error && <div className="error">{error}</div>}
</form> */
}
