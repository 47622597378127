import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import Navbar from "./components/Navbar";
import Login from './pages/Login';
import Signup from './pages/Signup';

import { useAuthContext } from './hooks/useAuthContext';
import Landing from './pages/Landing';
import Workouts from './pages/Workouts';

function App() {
  const { user } = useAuthContext()
  return (
    <BrowserRouter>
      <div className="relative z-0 bg-primary">
        {/* <div className="bg-hero-pattern bg-cover bg-no-repeat bg-center"> */}
          <Navbar />
          
        {/* </div> */}
       {/* <div> */}
       <Routes>
        <Route path='/'
          element={<Landing />}
          />
          <Route path='/workouts'
          element={user ? <Workouts /> : <Navigate to='/login' />}
          />
        <Route path='/login'
          element={!user ? <Login /> : <Navigate to='/workouts' />}
          />
            <Route path='/signup'
          element={!user ? <Signup /> : <Navigate to='/' />}
          />
          </Routes>
       {/* </div> */}
    
      

      </div>
    </BrowserRouter>
  );
}

export default App;
