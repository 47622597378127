import { useEffect, useState } from "react";
import WorkoutDetails from "../components/WorkoutDetails";
import WorkoutForm from "../components/WorkoutForm";
import { useWorkoutsContext } from "../hooks/useWorkoutsContext";
import { useAuthContext } from "../hooks/useAuthContext";

import { format } from "date-fns";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";

import { styles } from "../styles";

const Workouts = () => {
  const { workouts, dispatch } = useWorkoutsContext();
  const { user } = useAuthContext();
  const [selected, setSelected] = useState(Date.now());
  const [toggle, setToggle] = useState(false);
  const [toggleDate, setToggleDate] = useState(false);

  let footer = <p>Please pick a day.</p>;

  useEffect(() => {
    const fetchWorkouts = async () => {
      const response = await fetch(
        "https://track-your-lifts-backend.onrender.com/api/workouts",
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const json = await response.json();

      if (response.ok) {
        const timestamp = new Date(selected);
        const timestampString = timestamp.toLocaleDateString();

        const array = json.filter((workout) => {
          const date = new Date(workout.createdAt);
          const dateString = date.toLocaleDateString();

          return dateString === timestampString;
        });

        // setWorkouts(json);
        dispatch({ type: "SET_WORKOUTS", payload: array });
      }
    };
    if (user) {
      fetchWorkouts();
    }
  }, [dispatch, user, selected]);

  if (selected) {
    footer = (
      <p className="text-indigo-500">
        Showing workouts for {format(selected, "PP")}.
      </p>
    );
  }

  const workoutClick = () =>{
    setToggle(!toggle)
    if (toggleDate) {
      setToggleDate(!toggleDate)
    }
  }

  const dateClick = () =>{
    setToggleDate(!toggleDate)
    if (toggle) {
      setToggle(!toggle)
    }
  }

  

  return (
    <section className="relative w-full mt-5 h-screen mx-auto">
      
      <div
        className={`${styles.paddingX} absolute inset-0 top-[120px] mx-w-7xl fixed overflow-auto`}
      >
        
        <div className="w-full px-5">
          
          
        </div>

        <div className="flex w-full flex-wrap md:flex-nowrap ">
          <div className="mx-5 w-full md:w-1/2">
          <div className="grid grid-cols-2 gap-4">
          <div>
            <button
            onClick={() => workoutClick()}
            className="flex w-full overflow-hidden justify-center rounded-md 
            bg-indigo-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white 
            shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 
            focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
          >
            {toggle ? "Close" : "Add a workout"}
          </button>
          
            </div>
            <div className="sm:hidden">
            <button
            onClick={() => dateClick()}
            className="flex w-full overflow-hidden justify-center rounded-md 
            bg-indigo-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white 
            shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 
            focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
          >
            {toggleDate ? "Close" : "Select Date"}
          </button>
            </div>
            

          </div>
          
          
            
            {toggle && <WorkoutForm />}
            {toggleDate && <DayPicker
              mode="single"
              selected={selected}
              onSelect={setSelected}
              styles={{
                caption: { color: '#6366f1' },
                
              }}
              
              footer={footer}
            />}
            {/* <p>{Date(selected)}</p> */}

            {workouts && workouts.length > 0 ? (
              workouts.map((workout) => (
                <WorkoutDetails key={workout._id} workout={workout} />
              ))
            ) : (
              <div className="bg-indigo-500 rounded-lg mt-5 p-5">
                <h4>There are no workouts on this date.</h4>
              </div>
            )}
          </div>
          <div className="md:w-1/2 hidden sm:flex justify-end">
            {/* <WorkoutForm /> */}
            <DayPicker
              mode="single"
              selected={selected}
              onSelect={setSelected}
              styles={{
                caption: { color: '#6366f1' }
              }}
              footer={footer}
            />
          </div>

          <div />
        </div>
      </div>
    </section>
  );
};

export default Workouts;
