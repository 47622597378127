import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from '../assets/38464.png'
import menu from '../assets/menu.png'
import close from '../assets/close.png'

import { styles } from "../styles";
import { navLinks } from "../constants";
// import { logo, menu, close } from "../assets";

import { useLogout } from "../hooks/useLogout"
import { useAuthContext } from "../hooks/useAuthContext"

const Navbar = () => {
  const [active, setActive] = useState("");
  const [toggle, setToggle] = useState(false);

  const { logout } = useLogout()
  const { user } = useAuthContext()

  const handleClick = () => {
    logout()
}


  return (
    <nav
      className={`${styles.paddingX} w-full flex items-center py-5 fixed top-0 z-20 bg-black border-b-4 border-indigo-500
    `}
    >
      <div className="w-full flex justify-between items-center max-w-7x1 mx-auto">
        <Link
          to="/"
          className="flex items-center gap-2"
          onClick={() => {
            setActive("");
            window.scrollTo(0, 0);
          }}
        >
          <img src={logo} alt="logo" className="w-12 h-12 object-contain" />
          <p className="text-secondary text-[24px] font-bold cursor-pointer flex">
            Track Your:&nbsp;<span className="bg-gradient-to-r from-cyan-500 to-blue-500 text-transparent bg-clip-text">Workouts</span>
          </p>
        </Link>
        <ul className="list-none hidden sm:flex flex-row gap-10">
            {!user ? ( 
                <>
          {navLinks.map((link) => (
            <li
              key={link.id}
              className={`${
                active === link.title ? "text-indigo-500" : "text-secondary"
              } hover:text-white text-[18px] font-medium cursor-pointer`}
              onClick={() => setActive(link.title)}
            >
              <a href={`${link.id}`}>{link.title}</a>
            </li>
          ))}
          </>) : (<>
            <li
              key='workouts'
              className={`${
                active === 'workouts' ? "text-indigo-500" : "text-secondary"
              } hover:text-white text-[18px] font-medium cursor-pointer`}
              onClick={() => setActive('workouts')}
            >
              <a href={`workouts`}>Your Workouts</a>
            </li>
          <li
              key='logout'
              className={`${
                active === 'logout' ? "text-white" : "text-secondary"
              } hover:text-white text-[18px] font-medium cursor-pointer`}
              onClick={handleClick}
            >
              <a href={``}>Log Out</a>
            </li>
            </>)}
        </ul>
        
        <div className="sm:hidden flex flex-1 justify-end items-center">
          <img src={toggle ? close : menu} alt="menu" className="w-[28px] h-[28px] object-contain cursor-pointer"
          onClick={() => setToggle(!toggle)} />

          <div className={`${!toggle ? 'hidden' : 'flex'} p-6 bg-black absolute top-20 right-0 mx-4 my-2 min-w[140px] z-10 rounded-xl`}>
          <ul className="list-none flex justify-end items-start flex-col gap-4">
          {!user ? ( 
                <>
          {navLinks.map((link) => (
            
            <li
              key={link.id}
              className={`${
                active === link.title ? "text-white" : "text-secondary"
              } font-poppins font-medium cursor-pointer text-[16px]`}
              onClick={() => {
                setToggle(!toggle)
                setActive(link.title);
              }}
            >
              <a href={`${link.id}`}>{link.title}</a>
            </li>
          ))}
          </>
          ) : (<>
          <li
              key='workouts'
              className={`${
                active === 'workouts' ? "text-white" : "text-secondary"
              } font-poppins text-[16px] font-medium cursor-pointer`}
              onClick={() => setActive('workouts')}
            >
              <a href={`workouts`}>Your Workouts</a>
            </li>
          <li
              key='logout'
              className={`${
                active === 'logout' ? "text-white" : "text-secondary"
              } font-poppins text-[16px] font-medium cursor-pointer`}
              onClick={handleClick}
            >
              <a href={``}>Log Out</a>
            </li>
            </>)}
        </ul>
          </div>

        </div>
      </div>
    </nav>
  );
};

export default Navbar;


// {user && ( 
//     <div>
//         <span>{user.email}</span>
//         <button onClick={handleClick}>Log Out</button>
//     </div>
//     )}
//     {!user && (<div>
//         <Link to='/login'>Log In</Link>
//         <Link to='/signup'>Sign Up</Link>
//     </div>
//     )}