import {
  ArrowPathIcon,
  CloudArrowUpIcon,
  LockClosedIcon,
  ArrowTrendingUpIcon,
  StarIcon
} from "@heroicons/react/20/solid";
import { styles } from "../styles";

const features = [
  {
    name: "Track",
    description:
      "Our innovative workout tracking feature allows you to effortlessly log and monitor your workouts, giving you a clear picture of your progress and helping you stay on track towards your fitness goals.",
    href: "#",
    icon: CloudArrowUpIcon,
  },
  {
    name: "Analyze",
    description:
    "Take your fitness journey to the next level with our powerful analysis tools. Our app crunches the numbers, providing detailed insights and trends to help you fine-tune your workouts and maximize your results.",
    href: "#",
    icon: ArrowTrendingUpIcon,
  },
  {
    name: "Add Favourites",
    description:
    "Never lose your favorite workouts again! Our app lets you easily save and organize your favorite routines, so you can access them anytime, anywhere, and keep your fitness routine fresh and exciting.",
    href: "#",
    icon: StarIcon,
  },
];

export default function Landing() {
  return (
    <section className="relative w-full h-screen mx-auto">
      <div
        className={`${styles.paddingX} absolute fixed inset-0 top-[120px] mx-w-7xl mx-auto flex flex-row items-start gap-5`}
      >
        <div className=" py-24 sm:py-32  overflow-auto">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl lg:text-center">
              <h2 className="text-base font-semibold leading-7 text-indigo-400">
                Work smarter
              </h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-transparent bg-clip-text bg-gradient-to-r from-green-400 to-blue-500 sm:text-4xl">
                Everything you need to track your workouts
              </p>
              <p className="mt-6 text-lg leading-8 text-secondary">
                Supercharge your fitness journey with our cutting-edge workout
                tracking app, designed to help you achieve your goals faster and
                more efficiently than ever before.
              </p>
            </div>
            <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
              <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
                {features.map((feature) => (
                  <div key={feature.name} className="flex flex-col">
                    <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-secondary">
                      <feature.icon
                        className="h-5 w-5 flex-none text-indigo-400"
                        aria-hidden="true"
                      />
                      {feature.name}
                    </dt>
                    <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-secondary">
                      <p className="flex-auto">{feature.description}</p>
                      
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
