
  
  export const navLinks = [
    {
      id: "login",
      title: "Login",
    },
    {
      id: "signup",
      title: "Signup",
    },
    // {
    //   id: "contact",
    //   title: "Contact",
    // },
  ];
  

  

