import { useAuthContext } from "../hooks/useAuthContext";
import { useWorkoutsContext } from "../hooks/useWorkoutsContext";

// date fns
import formatDistanceToNow from "date-fns/formatDistanceToNow";
// import { format } from 'date-fns'

const WorkoutDetails = ({ workout }) => {
  const { dispatch } = useWorkoutsContext();
  const { user } = useAuthContext();

  const handleClick = async () => {
    if (!user) {
      return;
    }
    const response = await fetch("https://track-your-lifts-backend.onrender.com/api/workouts/" + workout._id, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    });
    const json = await response.json();

    if (response.ok) {
      dispatch({ type: "DELETE_WORKOUT", payload: json });
    }
  };

  return (
    <div className="bg-indigo-500 rounded-lg mt-5 p-5 ml-">
      <div className="px-4 sm:px-0 flex">
        <h3 className="text-base font-semibold leading-7 text-white w-1/2">
          {workout.title}
        </h3>
        <div className="flex justify-end w-1/2">
        <span className="material-symbols-outlined " onClick={handleClick}>
              delete
            </span>
        </div>
        
        {/* <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-400">Personal details and application.</p> */}
      </div>
      <div className="mt-6 border-t border-white/10">
        <dl className="divide-y divide-white/10">
          <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-white">
              Load (KG):
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-400 sm:col-span-2 sm:mt-0">
              {workout.load}
            </dd>
          </div>
          <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-white">Reps:</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-400 sm:col-span-2 sm:mt-0">
              {workout.reps}
            </dd>
            <p className="mt-1 text-sm leading-6 text-gray-400 sm:col-span-2 sm:mt-0">
              {formatDistanceToNow(new Date(workout.createdAt), {
                addSuffix: true,
              })}
            </p>

            
          </div>
        </dl>
      </div>
    </div>
  );
};

export default WorkoutDetails;

{
  /* <div className="ml-12 w-full">
<h4>{workout.title}</h4>
<p>
  <strong>Load (kg): </strong>
  {workout.load}
</p>
<p>
  <strong>Reps: </strong>
  {workout.reps}
</p>
<p>
  {formatDistanceToNow(new Date(workout.createdAt), { addSuffix: true })}
</p>

<span className="material-symbols-outlined" onClick={handleClick}>
  delete
</span>
</div> */
}
