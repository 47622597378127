import { useState } from "react";
import { useLogin } from "../hooks/useLogin";
import { styles } from "../styles";
import Error from "../components/ErrorMessage";
import { XCircleIcon } from '@heroicons/react/20/solid'

import logo from "../assets/38464.png";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
    const { login, error, isLoading } = useLogin()
    const [errorMessage, setErrorMessage] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(email, password)

    await login(email, password)
    
  };

  return (
    <section className="relative w-full h-screen mx-auto">
      <div
        className={`${styles.paddingX} absolute inset-0 top-[120px] mx-w-7xl mx-auto flex flex-row items-start gap-5`}
      >
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 ">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm ">
            <img
              className="mx-auto h-16 w-auto"
              src={logo}
              alt="Your Company"
            />
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-secondary">
              Sign in to your account
            </h2>
          </div>
          

          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm ">
          {error && (
                <div className="rounded-md bg-red-50 p-2 mb-5">
                <div className="flex">
                  <div className="flex-shrink-0">
                  <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800">Attention needed</h3>
                  <div className="mt-2 text-sm text-red-700">
                      <p>
                        {error}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              ) }
            <form className="space-y-6" action="#" method="POST" onSubmit={handleSubmit}>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-secondary"
                >
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    required
                    className="block w-full rounded-md border-2 border-indigo-500 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium leading-6 text-secondary"
                  >
                    Password
                  </label>
                  <div className="text-sm">
                    <a
                      href="#"
                      className="font-semibold text-indigo-400 hover:text-indigo-300"
                    >
                      Forgot password?
                    </a>
                  </div>
                </div>
                <div className="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    required
                    className="block w-full rounded-md border-2 border-indigo-500 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  disabled={isLoading}
                  className="flex w-full justify-center rounded-md bg-indigo-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                >
                  Sign in
                </button>
                
              </div>
              
            </form>

            <p className="mt-10 text-center text-sm text-gray-400">
              Not a member?{" "}
              <a
                href="signup"
                className="font-semibold leading-6 text-indigo-400 hover:text-indigo-300"
              >
                Create an account
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;

{
  /* <form className="login" onSubmit={handleSubmit}>
      <h3>Log In</h3>

      <label>Email:</label>
      <input
        type="email"
        onChange={(e) => setEmail(e.target.value)}
        value={email}
      />

      <label>Password:</label>
      <input
        type="password"
        onChange={(e) => setPassword(e.target.value)}
        value={password}
      />
      <button >Submit</button>
      {error && <div className="error">{error}</div>}
    </form> */
}
